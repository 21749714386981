
import FontFaceObserver from 'fontfaceobserver'
import { useGlobalFontManager } from './useGlobalFontManager'
import { onUnmounted } from 'vue';

export const useUseRenderText = ({ layer, name, wish, template }) => {

  const fontFamily = wish.fontFamily()

  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  ctx.font = `normal 20px ${fontFamily}`;

  var isFontLoaded = false;
  var TEXT_TEXT = 'Demo ทดสอบ';
  var initialMeasure = ctx.measureText(TEXT_TEXT);
  var initialWidth = initialMeasure.width;


  let textClone = null;
  let wrapped = null;
  init()

  onUnmounted(() => {

    try {
      //destroy canvas and ctx
      canvas.remove()
      ctx = null

    } catch (error) {

    }
  })

  return null

  async function init() {
    loadFonts()

    applyAutoSize();

  }

  function applyAutoSize(retry = false) {

    retry && console.log('reapply autosize')


    wrapped && wrapped.destroy()
    textClone && textClone.destroy()
    // WISH
    if (wish) {
      // create wrapped texts
      if (wish.getAttrs().words) {
        textClone = wish.clone()
        wrapped = wrappedWishText(textClone, wish.getAttrs().words, [30, 48])
        layer.add(wrapped)
        // remove original text
        wish.text('')

      }

    }

    if (name) {
      let maxFontSize = Math.max(32, name.fontSize())
      fontAutoSize(name, [24, maxFontSize])
      //nothing to do
    }
  }

  function applyAutoSizeAgain() {
    console.log('reapply autosize')
    // WISH
    wrapped && wrapped.destroy()
    textClone && textClone.destroy()

    if (wish) {
      // create wrapped texts
      if (wish.getAttrs().words) {
        let textClone2 = wish.clone()
        const wrapped2 = wrappedWishText(textClone2, wish.getAttrs().words ,  [30,48])
        layer.add(wrapped2)
        // remove original text
        wish.text('')

      }

    }

  }

  function addCssLink(fontFamily) {

    //only font for photowish template
    // const url = 'https://fonts.googleapis.com/css2?family=' + fontFamily  + ':wght@400;700' // + '&display=swap'
    const url = 'https://fonts.googleapis.com/css2?family=' + fontFamily      // + '&display=swap'
    const encoded = encodeURI(url)
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = encoded
    document.head.appendChild(link)
  }


  function fontAutoSize(textElement, _autoSize = [22, 48]) {

    // get rect of text element

    let { x, y, width, height, autoSize, text } = textElement.getAttrs()


    let [min, max] = _autoSize

    //do auto size
    let count = 0
    let _fontSize = min
    textElement.fontSize(min)

    let text_removed_all_spaces = text.replace(/ +/g, '');

    // text remove all newlines
    let text_removed_all_newlines = text_removed_all_spaces.replace(/(\r\n|\n|\r)/gm, "");

    while (hasEmptySpace(textElement, text_removed_all_newlines, _fontSize + 1) && (_fontSize + 1) <= max && count < 40) {
      _fontSize++
      count++
    }
    textElement.fontSize(_fontSize)


    console.log(`${textElement.name()} autosize font at : `, _fontSize)

    function hasEmptySpace(container, text_removed_all_spaces, nextFontSize) {
      container.fontSize(nextFontSize)

      let sum = 0
      for (let index = 0; index < container.textArr.length; index++) {
        const element = container.textArr[index].text.replace(/ +/g, '');

        sum += element.length;
      }

      if (sum == text_removed_all_spaces.length) {
        return true
      } else {
        return false
      }


    }
  }

  function wrappedWishText(element: any, words: Array<string>, _autoSize = [22, 48]) {
    let shape = new Konva.Shape({
      x: element.x() || 0,
      y: element.y() || 0,
      width: element.width(),
      height: element.height(),

      sceneFunc: function (context, shape) {

        drawTextToFitArea(context, words, element.width(), element.height(), {
          minFontSize: _autoSize[0],
          maxFontSize: _autoSize[1],
          verticalAlign: element.verticalAlign() || "top",
          horizontalAlign: element.align() || "left",
          fontFamily: element.fontFamily() || "Arial",
          // fontWeight: "bold",
          lineHeight: element.lineHeight() || 1.5,
          fill: element.fill() || "black",
        });
      }
    });

    return shape;
  }

  function drawTextToFitArea(ctx, _words, maxWidth, maxHeight, options = {}) {
    const minFontSize = options.minFontSize || 10;
    const maxFontSize = options.maxFontSize || 100;
    const verticalAlign = options.verticalAlign || "top";
    const horizontalAlign = options.horizontalAlign || "left";
    const fontFamily = options.fontFamily || "Arial";
    const fontWeight = options.fontWeight || "normal";
    const lineHeightMultiplier = options.lineHeight || 1.5;
    const fill = options.fill || "black";
    //color fill
    ctx.fillStyle = fill;

    let optimalFontSize = minFontSize;
    let low = minFontSize;
    let high = maxFontSize;
    let lines = [];
    let count = 0;
    // Binary search to find the optimal font size
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      ctx.font = `${fontWeight} ${mid}px ${fontFamily}`;
      lines = [];
      let currentLine = _words[0] // text.split(" ")[0];
      const words = _words // text.split(" ");

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const width = ctx.measureText(currentLine + word).width;
        if (width < maxWidth) {
          currentLine += word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      lines.push(currentLine);

      const lineHeight = mid * lineHeightMultiplier;
      const totalHeight = lines.length * lineHeight;

      if (totalHeight <= maxHeight && lines.every(line => ctx.measureText(line).width <= maxWidth)) {
        optimalFontSize = mid;
        low = mid + 1;
      } else {
        high = mid - 1;
      }
      count++;

      if (count > 50) {
        count = 0;
        break;
      }
    }
    optimalFontSize -= 1;

    const lineHeight = optimalFontSize * lineHeightMultiplier;

    ctx.font = `${fontWeight} ${optimalFontSize}px ${fontFamily}`;
    ctx.textBaseline = "top";

    let y;
    if (verticalAlign === "middle") {
      y = Math.max((maxHeight - lines.length * lineHeight) / 2, 0);
    } else if (verticalAlign === "bottom") {
      y = Math.max(maxHeight - lines.length * lineHeight, 0);
    } else {
      y = 0;
    }
    y += 10;

    lines.forEach((line, i) => {
      let x;
      if (horizontalAlign === "center") {
        x = (maxWidth - ctx.measureText(line).width) / 2;
      } else if (horizontalAlign === "right") {
        x = maxWidth - ctx.measureText(line).width;
      } else {
        x = 0;
      }
      ctx.fillText(line  , x, y);
      y += lineHeight;
    });
  }



  function loadFonts() {


    let fontName = name.fontFamily()
    let promiseFontName = new FontFaceObserver(fontName).load(null)

    let fontWsih = wish.fontFamily()
    let promiseFontWish = new FontFaceObserver(fontWsih).load(null)
    addCssLink(fontName)
    addCssLink(fontWsih)

    return Promise.all([promiseFontName, promiseFontWish])
      .then((a) => {
        console.log('all text loaded')
      })
      .catch((e) => {
        console.log('error loading text', e)
      })
      .finally(() => {
        setTimeout(() => {

          const metrics = ctx.measureText(TEXT_TEXT);
          const width = metrics.width;
          if (width !== initialWidth) {
            applyAutoSize()
          }
          // debugger
          // if (useGlobalFontManager().getFontLoaded(template.templateId) == false) {
          //   useGlobalFontManager().setFontLoaded(template.templateId)
          //   applyAutoSize()
          //   layer.batchDraw()
          // } else {
          //   layer.batchDraw()
          // }
          layer.batchDraw()

        }, 10);

      })
  }



}
