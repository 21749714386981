import { defineStore } from 'pinia'
import { db } from '../service/firebase_config'

import { doc, query, onSnapshot, collection, where, getCountFromServer } from 'firebase/firestore'

import { useEventStore } from './event'
import _ from 'lodash'

export const usePromptpayStore = defineStore({
  id: 'promptpay',
  state: () => {
    return {
      promptpayLength: 0,
    }
  },
  actions: {
    async main() {
      await this.fetchPromptpay()
    },

    async fetchPromptpay() {

      const p = new Promise(async (resolve) => {
        const eventStore = useEventStore()
        const eventId = eventStore.eventId

        const coll = collection(db,`events/${eventId}/promptpay`) 
        const q = query(coll, 
          where("eventId", "==", eventId), 
          where("status", "==", 'success'),
        );

        const snapshot = await getCountFromServer(q);
        const data = snapshot.data()
        this.promptpayLength = data?.count || 0
        resolve(true)


        // ----- query : เอาดาต้าด้วย ---------
        // const q = query(
        //   collection(db,`events/${eventId}/promptpay`)      
        // );
    
        
        // onSnapshot(q, (querySnapshot: any) => {     
        //   let list:any = []
        //   querySnapshot.forEach((doc) => {    
    
        //     const data = doc.data()
        //     if(data?.status === 'success') {
        //       list.push({ id: doc.id, ...doc.data() })    
        //     }
        //   }); 

        //   this.promptpayList = list
          // resolve(true)
        // });
      })

      return p
    },
  },
  getters: {}
})